<template>
	<div :class="{[$info.wrapper]:1, [$info.loading]:loading}">
		<div :class="$info.title">Моя информация</div>
		<div :class="$info.main">
			<div :class="$info.note">Вы в любой момент можете обновить вашу учетную запись «Вкусно и Быстро» и внести
				любые изменения в приведенные ниже данные.
			</div>
			<form
				:class="$info.personal"
				@submit.prevent="updateProfileInfo"
			>
				<div :class="$info.input">
					<label
						for="first_name"
						:class="$info.text"
					>Имя</label>
					<input
						:class="{[$info.infoRow]:1, [$info.err]:inputErrors.firstName}"
						type="text"
						id="first_name"
						v-model="firstName"
						:disabled="loading"
					>
					<div :class="{[$info.inputError]:1, [$info.invisible]:!inputErrors.firstName}">{{
							inputErrors.firstName
						}}
					</div>
				</div>
				<div :class="$info.input">
					<label
						for="last_name"
						:class="$info.text"
					>Фамилия</label>
					<input
						:class="{[$info.infoRow]:1, [$info.err]:inputErrors.lastName}"
						type="text"
						id="last_name"
						v-model="lastName"
						:disabled="loading"
					>
					<div :class="{[$info.inputError]:1, [$info.invisible]:!inputErrors.lastName}">{{
							inputErrors.lastName
						}}
					</div>
				</div>
				<div :class="$info.input">
					<label
						for="phone"
						:class="$info.text"
					>Телефон</label>
					<PhoneInput
						:class="{[$info.infoRow]:1, [$info.err]:inputErrors.phone}"
						id="phone"
						v-model="phone"
						:value="phone"
					/>
					<div :class="{[$info.inputError]:1, [$info.invisible]:!inputErrors.phone}">{{
							inputErrors.phone
						}}
					</div>
				</div>
				<div :class="$info.input">
					<label
						for="email"
						:class="$info.text"
					>Адрес электронной почты</label>
					<input
						:class="{[$info.infoRow]:1, [$info.err]:inputErrors.email}"
						type="text"
						id="email"
						v-model="email"
						:disabled="loading"
					>
					<div :class="{[$info.inputError]:1, [$info.invisible]:!inputErrors.email}">{{
							inputErrors.email
						}}
					</div>
				</div>
				<button
					:class="$info.btn"
					:disabled="loading"
				>Сохранить изменения
				</button>
			</form>
			<div :class="{[$info.updateError]:1, [$info.invisible]:!errorMsg}">Ошибка обновления: {{ errorMsg }}</div>
			<div :class="{[$info.success]:1, [$info.invisible]:!success}">Данные успешно сохранены</div>
			<!--<div :class="$info.line"></div>
			<div :class="$info.title2">Профили в социальных сетях</div>
			<div :class="$info.note">Теперь войти стало еще проще с помощью учетных записей в социальных сетях.</div>
			<div :class="$info.socials">
				<div :class="$info.item" v-for="el in list">
					<div :class="$info.icon">
						<img :src="el.img">
					</div>
					<span>{{el.title}}</span>
				</div>
			</div>-->
		</div>
	</div>
</template>

<script>
import PhoneInput from '@/components/form/PhoneInput.vue';
import {mapActions} from 'vuex'
import axios from "axios";
import qs from "qs";

export default {
	components: {PhoneInput},
	data()
	{
		return {
			firstName: '',
			lastName: '',
			phone: '',
			email: '',
			inputErrors: {
				firstName: false,
				lastName: false,
				phone: false,
				email: false,
			},
			loading: false,
			errorMsg: false,
			success: false,
			list: [
				{img: require('@/assets/img/Personal/socials/google.svg'), title: "Google"},
				{img: require('@/assets/img/Personal/socials/fb.svg'), title: "Facebook"},
				{img: require('@/assets/img/Personal/socials/git.svg'), title: "Git"},
			]
		}
	},
	methods: {
		clearErrors()
		{
			this.inputErrors.firstName = false;
			this.inputErrors.lastName = false;
			this.inputErrors.phone = false;
			this.inputErrors.email = false;
			this.errorMsg = false;
		},
		async loadProfileInfo()
		{
			this.loading = true;
			let resp = await axios.post('/personal/getProfileInfo/');
			if (resp.data.success)
			{
				this.firstName = resp.data.data.firstName;
				this.lastName = resp.data.data.lastName;

				let number = resp.data.data.phone;
				let mask = /(\d{3})(\d{3})(\d{2})(\d{2})/g;
				this.phone = number ? number.replace(mask, '+7($1)$2-$3-$4') : '';

				this.email = resp.data.data.email;
			}
			this.loading = false;
		},
		async updateProfileInfo()
		{
			this.loading = true;
			this.success = false;
			this.clearErrors();
			if (!this.validateFields())
			{
				this.loading = false;
				return false;
			}

			let data = qs.stringify({
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				phone: this.phone
			});

			let resp = await axios.post('/personal/updateProfileInfo/', data);

			this.loading = false;

			if (resp.data.success)
			{
				this.success = true;
				this.loadProfileInfo();
			} else if (resp.data.error.showMsg)
			{
				this.errorMsg = resp.data.error.showMsg;
			}
			this.$store.dispatch('isLogged');
		},
		validateFields()
		{
			let noErrors = true;

			if (!this.firstName)
			{
				this.inputErrors.firstName = 'Обязательное поле';
				noErrors = false;
			}
			if (!this.email)
			{
				this.inputErrors.email = 'Обязательное поле';
				noErrors = false;
			} else
			{
				let mask = /^\w.+@\w+\.\w{2,10}$/gi;
				if (!mask.test(this.email))
				{
					this.inputErrors.email = 'Email введен некорректно';
					noErrors = false;
				}
			}

			let mask = /[^\d]+/g;
			let phone = this.phone.replace(mask, '');

			if (phone.length !== 11)
			{
				this.inputErrors.phone = 'Телефон введен некорректно';
				noErrors = false;
			}

			return noErrors;
		}
	},
	mounted()
	{
		this.loadProfileInfo();
	}
}
</script>

<style
	module="$info"
	lang="scss"
>
.wrapper
{
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 32px 22px;
	align-content: start;

	font-family: $mainFont;

	.title
	{
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 16px;
		font-family: $mainFontBold;
		@media (max-width: 320px)
		{
			font-size: 16px;
		}
	}

	.main
	{
		max-width: 678px;
	}

	&.loading
	{
		opacity: 0.5;
	}
}

.note
{
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #979797;

	@media (max-width: 320px)
	{
		font-size: 12px;
		line-height: 16px;
	}
}

.personal
{
	display: grid;

	grid: "input input" 1fr
              "input input" 1fr
              "btn btn" 1fr
              / 1fr 1fr;

	grid-gap: 32px;
	margin-top: 32px;
	@media (max-width: 980px)
	{
		grid-template:  "input" 1fr
                            "input" 1fr
                            "input" 1fr
                            "input" 1fr
                            "btn" 1fr;
	}

	.infoRow
	{
		grid-area: input;
		width: 100%;
		min-height: 56px;
		height: fit-content;
		padding: 22px 24px 10px 24px;
		border: 2px solid $greyBorder;
		border-radius: 8px;
		background-color: $greyBackground;
	}

	.input
	{
		position: relative;

		.text
		{
			position: absolute;
			top: 8px;
			left: 26px;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			color: $greyLightText;
			width: 100%;
			font-family: $mainFontMedium;
			@media (max-width: 320px)
			{
				font-size: 12px;
			}
		}
	}

	.btn
	{
		all: unset;
		cursor: pointer;
		grid-area: btn;
		display: grid;
		height: 56px;
		background: $green;
		border-radius: 100px;
		color: white;
		place-content: center;
		font-family: $mainFontBold;
	}
}

.updateError
{
	color: red;
	margin: 20px;
	font-size: 14px;
	text-align: center;
}

.success
{
	font-family: $mainFontBold;
	color: $green;
	margin: 20px;
	font-size: 14px;
	text-align: center;
}

.inputError
{
	color: red;
	margin: 2px;
	font-size: 10px;
	position: absolute;
	top: 52px;
	left: 3px;
}

.infoRow.err
{
	border: 1px solid red;
}

.invisible
{
	display: none;
}

.line
{
	margin-top: 56px;
	margin-bottom: 32px;
	width: 100%;
	height: 0px;
	border: 1px solid #F0F0F0;
}

.title2
{
	font-weight: bold;
	font-size: 18px;
	line-height: 32px;
	margin-bottom: 16px;

	@media (max-width: 320px)
	{
		font-size: 14px;
		line-height: 18px;
	}
}

.socials
{
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 440px)
	{
		flex-direction: column;
	}

	.item
	{
		display: flex;
		cursor: pointer;

		.icon
		{
			width: 48px;
			height: 48px;
			background: #F8F8F8;
			border-radius: 50%;
			padding: 12px;

			img
			{
				width: 100%;
				height: 100%;
				margin: auto auto;
			}
		}

		span
		{
			margin: auto 16px;
			display: block;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #3D4248;
		}
	}
}
</style>
